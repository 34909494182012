<template>
  <div class="stemwijzer" v-if="!loading">
    <div class="parties" v-if="showParty">
      <p class="mb-2">Waar zou je nu op stemmen?</p>
      <select v-model="selectedParty" :required="true">
        <option value="" disabled selected>Maak een keuze</option>
        <option v-for="(party, index) in parties" :key="index" :value="party.name">{{ party.name }}</option>
      </select>
      <br/>
      <br/>
      <a @click="startQuiz" :disabled="!selectedParty">Start</a>
    </div>

    <div class="statement" v-if="showStatement">
      <div class="progress-bullets flex justify-center items-center gap-2">
        <div v-for="(statement, index) in statements" :key="statement.id"
             class="rounded-full" :class="index === current ? 'bullet active outline outline-2 outline-[#d42020] bg-[#F0F0F0] w-3.5 h-3.5' : (answers[statement.id]!=''&& answers[statement.id]!='skip') ? 'bullet voted bg-[#d42020] w-3.5 h-3.5' : 'bullet bg-[#F0F0F0] w-3.5 h-3.5'" >
        </div>
      </div>
      <div class="container">
        <h2>{{ statement.title }}</h2>
        <b>Voorstanders: </b>
        <p class="mb-1" v-html="addLineBreaks(statement.pro)"></p>
        <b>Tegenstanders: </b>
        <p class="mb-1" v-html="addLineBreaks(statement.con)"></p>
        <br>
        <button @click="collapsed = !collapsed">
          <span class="meerinfo"
                v-html="collapsed ? '<b class=meerinfodicht>Meer informatie</b>' : '<b class=meerinfoopen>Minder informatie</b>'"></span>
        </button>
        <div v-show="!collapsed">
          <p class="mb-5" style="white-space: pre-wrap;">{{ statement.statement }}</p>
        </div>
        <br>
        <div class="wrapper mb-10">
          <input type="radio" name="vote" value="pro" id="option-1" @click="setVote">
          <input type="radio" name="vote" value="con" id="option-2" @click="setVote">
          <label for="option-1" class="option option-1">
            <span>Voor</span>
          </label>
          <label for="option-2" class="option option-2">
            <span>Tegen</span>
          </label>
        </div>
        <br/>
        <span class="error" v-if="errorVote">Maak een keuze</span>
        <a class="skip-link" @click="nextQuestion(true)">Overslaan ></a>
        <br>
        <a @click="nextQuestion(false)">Stem</a>
      </div>
    </div>

    <div class="results" v-if="showEnd">
      <div class="resultoverview" v-if="!selectedPartyResult">
        <div class="shareable">
          <div id="share-card">
            <div class="share-card-title">Deze partij past het best bij mij:</div>
            <div class="share-card-body">
              <div
                  class="share-card-partij"
                  v-for="(partij, index) in topParties"
                  :key="index"
              >
                <img :src="'/stemwijzer'+partijLogos[partij.name]" :alt="partij.name">
                <div class="share-card-partij-percentage">
                  {{ partij.percentage }}%
                </div>
              </div>
            </div>
            <div class="share-card-footer">
              Doe zelf de test op <strong>checkjestem.nl</strong>
            </div>
          </div>
          <div class="share-button">
            <button v-on:click="shareResult()">
              <svg id="lnr-exit-up" viewBox="0 0 1024 1024">
                <path class="path1"
                      d="M793.6 1024h-614.4c-42.347 0-76.8-34.453-76.8-76.8v-512c0-42.347 34.453-76.8 76.8-76.8h204.8c14.138 0 25.6 11.462 25.6 25.6s-11.462 25.6-25.6 25.6h-204.8c-14.115 0-25.6 11.485-25.6 25.6v512c0 14.115 11.485 25.6 25.6 25.6h614.4c14.115 0 25.6-11.485 25.6-25.6v-512c0-14.115-11.485-25.6-25.6-25.6h-204.8c-14.139 0-25.6-11.462-25.6-25.6s11.461-25.6 25.6-25.6h204.8c42.347 0 76.8 34.453 76.8 76.8v512c0 42.347-34.453 76.8-76.8 76.8z"></path>
                <path class="path2"
                      d="M658.099 186.699l-153.6-153.6c-9.997-9.998-26.206-9.998-36.203 0l-153.6 153.6c-9.998 9.997-9.998 26.206 0 36.203s26.206 9.998 36.203 0l109.901-109.899v578.197c0 14.139 11.462 25.6 25.6 25.6s25.6-11.461 25.6-25.6v-578.197l109.901 109.898c4.997 5 11.547 7.499 18.099 7.499s13.102-2.499 18.099-7.499c10-9.997 10-26.205 0-36.202z"></path>
              </svg>
              Delen
            </button>
          </div>
        </div>
        <div class="result">
          <h1>Jouw resultaat:</h1>
          <p>Klik op een partij om jouw resultaat te vergelijken.</p>
          <table class="results-table">
            <tr v-for="result in results" :key="result.name"
                :class="selectedParty == result.name ? 'selected cursor-pointer' : 'cursor-pointer'"
                v-on:click="handleResultClick(result.name)">
              <td><span class="flex justify-between">{{ result.name }}<span class="self-center bg-white rounded-full p-[5px] "><svg class="w-[15px] h-[15px]" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
<path fill="#000000" d="M0 6c0-0.128 0.049-0.256 0.146-0.354 0.195-0.195 0.512-0.195 0.707 0l8.646 8.646 8.646-8.646c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-9 9c-0.195 0.195-0.512 0.195-0.707 0l-9-9c-0.098-0.098-0.146-0.226-0.146-0.354z"></path>
</svg></span>
</span></td>
              <td v-if="innerWidth > 430">{{ result.percentage }} %</td>
              <td v-else>{{ result.percentage }}%</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="resultdetails relative" v-if="selectedPartyResult">
        <button class="absolute top-0 left-0 p-2.5 rounded-full bg-[#F0F0F0]" v-on:click="()=>{selectedPartyResult = ''}"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg></button>
          <h1 class="max-[390px]:pl-10 text-center mb-4">{{ selectedPartyResult }}</h1>
        <table class="results-table">
          <tr>
            <th></th>
            <th class="font-light">Jij</th>
            <th><img :src="'/stemwijzer'+partijLogos[selectedPartyResult]" :alt="selectedPartyResult" class="max-w-[45px] max-h-[35px] object-contain w-[45px] h-[35px]"></th>
          </tr>
          <tr v-for="statement in statements" :key="statement.title">
            <td>{{ statement.title }}</td>
            <td class="text-center font-extrabold">{{ answers[statement.id] === 'pro' ? '✅' : answers[statement.id] === 'con' ? '❌' : '—' }}</td>
            <td class="text-center" v-if="statement.partyVotes.find(partyVote => partyVote.name === selectedPartyResult).position === 'con'">
              ❌
            </td>
            <td class="text-center" v-else-if="statement.partyVotes.find(partyVote => partyVote.name === selectedPartyResult).position === 'pro'">
              ✅
            </td>
            <td class="text-center font-extrabold" v-else>—</td>
          </tr>
        </table>
      </div>
<!--      <button v-on:click="debugDoAction()">-->
<!--        Debug-->
<!--      </button>-->

    </div>
    <span v-if="showError" class="error-message">{{ errorMessage }}</span>
  </div>
</template>

<script>
import axios from 'axios';
import * as htmlToImage from 'html-to-image';
// https://www.npmjs.com/package/merge-images mogelijk alternatief als html-to-image niet werkt op verschillende browsers

import {saveAs} from 'file-saver';

export default {
  name: 'StemWijzer',
  data() {
    return {
      current: 0,
      errorMessage: 'Selecteer een optie om verder te gaan',
      loading: false,
      parties: {},
      selectedParty: '',
      showEnd: false,
      showError: false,
      showParty: false,
      showStatement: false,
      statements: [],
      totalStatements: 0,
      answeredQuestions: 0,
      vote: '',
      collapsed: true,
      results: [],
      answers: {},
      selectedPartyResult: "",
      innerWidth: window.innerWidth,
      partijLogos: {
        "Belang van Nederland": require('../assets/partij-logos/Belang van Nederland.svg'),
        "CDA": require('../assets/partij-logos/CDA.svg'),
        "CU": require('../assets/partij-logos/CU.svg'),
        "D66": require('../assets/partij-logos/D66.svg'),
        "DENK": require('../assets/partij-logos/DENK.svg'),
        "Forum voor Democratie": require('../assets/partij-logos/Forum_voor_Democratie.svg'),
        "GroenLinks": require('../assets/partij-logos/GroenLinks.svg'),
        "PvdA": require('../assets/partij-logos/PvdA.svg'),
        "Partij voor de Dieren": require('../assets/partij-logos/Partij voor de Dieren.svg'),
        "PVV": require('../assets/partij-logos/PVV.svg'),
        "SGP": require('../assets/partij-logos/SGP.svg'),
        "SP": require('../assets/partij-logos/SP.svg'),
        "VVD": require('../assets/partij-logos/VVD.svg'),
        "Volt": require('../assets/partij-logos/Volt.svg'),
        "JA21": require('../assets/partij-logos/JA21.svg'),
        "BIJ1": require('../assets/partij-logos/Bij1.svg'),
        "BBB": require('../assets/partij-logos/BoerBurgerBeweging.svg'),
        "Fractie den Haan": require('../assets/partij-logos/Fractie Den haan.svg'),
        "Nieuw Sociaal Contract": require('../assets/partij-logos/Nieuw Sociaal Contract.svg'),
        "BVNL": require('../assets/partij-logos/BVNL.svg'),
        "GroenLinks-PvdA": require('../assets/partij-logos/GroenLinks–PvdA.svg'),
      }
    }
  },

  computed: {
    statement() {
      return this.statements[this.current];
    },
    topParties() {
      // Sort the parties based on percentage in descending order
      const sortedParties = this.results.slice().sort((a, b) => b.percentage - a.percentage);
      // Get the top 3 parties
      return sortedParties.slice(0, 3);
    },
  },

  created() {
    this.loading = true;
    this.fetchParties();
    this.fetchStatements();
  },

  methods: {
    startQuiz() {
      if (!this.selectedParty) {
        this.showError = true;
        return;
      }

      this.showParty = false;
      this.showStatement = true;
      this.showError = false;
    },

    addLineBreaks(str) {
      if (typeof str !== "string") {
        return;
      }
      const items = str.split(/([❌✅])/);
      let result = "";
      for (let i = 0; i < items.length; i++) {
        if (items[i] === "❌" || items[i] === "✅") {
          result += items[i];
        } else {
          if (i !== 0) {
            result += items[i] + "<br>";
          } else {
            result += items[i];
          }
        }
      }
      return result;
    },

    async shareResult() {
      let node = document.getElementById("share-card");

      const scale = 3

      const style = {
        transform: 'scale(' + scale + ')',
        transformOrigin: 'top left',
        width: node.offsetWidth + "px",
        height: node.offsetHeight + "px"
      }

      const param = {
        height: node.offsetHeight * scale,
        width: node.offsetWidth * scale,
        quality: 1,
        style
      }

      // eslint-disable-next-line no-unused-vars
      await htmlToImage.toPng(node, param).then(async function (blob) {
        await htmlToImage.toPng(node, param).then(async function (blob){
          // const webShareSupported = 'canShare' in navigator;
          //convert png to blob
          // const realblob = new Blob([atob(blob.split(',')[1])], {type: 'image/png'});

          // console.log(blob);
          // console.log(blob.type);
          // console.log(webShareSupported);
          // let parser = new UAParser();


          // if (webShareSupported) {
          //   const shareData = {
          //     files: [new File([blob], 'mijn-checkjestem-resultaat.png'), {
          //       type: 'image/png'
          //     }],
          //     title: 'Mijn Checkjestem resultaat',
          //     text: 'Doe zelf de test op checkjestem.nl',
          //     url: '/'};
          //   try{
          //     await navigator.share(shareData);
          //   }catch (err){
          //     if (err.name !== 'AbortError') {
          //       console.error(err.name, err.message);
          //     }
          //   }
          // }else{
          if (window.saveAs) {
            window.saveAs(blob, 'mijn-checkjestem-resultaat.png');
          } else {
            saveAs(blob, 'mijn-checkjestem-resultaat.png');
          }
          // }
        })
      });
    },
    handleResultClick(selectedPartyName){
      this.selectedPartyResult = selectedPartyName;
      window.scrollTo(0,0);
    },
    debugDoAction() {
      console.log("statements", this.statements);
      console.log("answers", this.answers);
      console.log("results", this.results);
      console.log("parties", this.parties);
      console.log("topParties", this.topParties);
    },
    async fetchParties() {
      this.loading = true;
      const baseURI = '/api/party.php';
      await axios.get(baseURI).then((result) => {
        result.data.forEach((party) => {
          this.parties[party.name] = {name: party.name, count: 0};
        })
      }).finally(() => {
        this.loading = false;
        this.showParty = true;
      });
    },

    async fetchStatements() {
      this.loading = true;
      const baseURI = '/api/index.php';
      await axios.get(baseURI).then((result) => {
        const data = result.data;
        this.totalStatements = data.length;
        this.statements = data;
      }).finally(() => {
        this.loading = false;
      });
      this.statements.forEach((statement) => {
        this.answers[statement.id] = '';
      });
    },

    async fetchResults(statementId) {
      this.results = [];
      const baseURI = '/api/result.php?id=' + statementId;
      return axios.get(baseURI).finally(() => {
        this.loading = false;
      });
    },

    async addVote(statement_id, vote) {
      const baseURI = '/api/vote.php?statement_id=' + statement_id + '&vote=' + vote;
      axios.post(baseURI, {
        statement_id: statement_id,
        vote: vote
      });
    },

    async submitResults() {
      const baseURI = '/api/submit.php?party=' + this.selectedParty;
      axios.post(baseURI, {
        party: this.selectedParty,
        results: this.answers,
      });
    },

    setVote(event) {
      this.vote = event.target.value;
    },

    async nextQuestion(skip = false) {
      this.loading = true;
      if (this.vote || skip) {
        if (this.vote == 'pro' || this.vote == 'con') {
          let currentParties = [];
          // if(!results){
          let results = await this.fetchResults(this.statement.id);
          // }
          if (skip !== true) {
            results.data.forEach((result) => {
              if (!currentParties.includes(result.name)) {
                currentParties.push(result.name);
                if (this.vote === result.position) {
                  this.parties[result.name].count++;
                }
              }
            });
            const statement_id = this.statements[this.current].id;
            const vote = this.vote;

            this.addVote(statement_id, vote);
            this.answers[statement_id] = vote;

            this.answeredQuestions++;
            this.$nextTick(() => {
              this.showError = false;
              this.vote = '';
            });
          }
        }
        if (skip === true) {
          this.answers[this.statements[this.current].id] = 'skip';
          this.$nextTick(() => {
            this.showError = false;
            this.vote = '';
          });
        }
        if (this.current < this.totalStatements - 1) {
          this.collapsed = true
          this.current++;
          this.loading = false;
        } else {
          this.getResults();
          this.showStatement = false;
          this.showEnd = true;
          this.loading = false;
        }
      } else {
        this.loading = false;
        this.showError = true;
      }
    },

    getResults() {
      Object.keys(this.parties).forEach((key) => {
        const percentage = Math.round((this.parties[key].count / this.answeredQuestions) * 100);
        const formattedPercentage = isNaN(percentage) ? 0 : percentage;
        this.results.push({
          name: this.parties[key].name,
          percentage: formattedPercentage,
        });
      });
      this.results.sort((a, b) => b.percentage - a.percentage);
      this.submitResults();
    }
  }
}
</script>

<style scoped>
.statement, .parties, .results {
  /*width: 60%;*/
  margin: auto;
  background: white;
  border-radius: 20px;
}

.parties, .results {
  padding: 30px;
}

.meerinfo {
  color: #DF3629;
}

/*.container {*/
/*    margin: 0 auto;*/
/*}*/

a {
  color: #08a15c;
  cursor: pointer;
  font-size: 20px;
}

a:hover,
.selected {
  color: #0ab869;
}

select {
  color: rgba(49, 46, 46, 0.712);
  border-radius: 5px;
  padding: 5px;
}

.error {
  color: #C94242;
}

.wrapper {
  display: inline-flex;
  height: 100px;
  width: 100%;
  gap: 100px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 20px 15px;
}

@media only screen and (max-width: 450px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    width: unset;
    gap: 50px;
  }

  .option {
    padding: 10px !important;
  }
}

.wrapper .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}

input[type="radio"] {
  display: none;
}

#option-1:checked:checked ~ .option-1 {
  border-color: #abcc7a;
  background: #abcc7a;
}

#option-2:checked:checked ~ .option-2 {
  border-color: #f48383;
  background: #f48383;
}

.wrapper .option span {
  font-size: 20px;
  color: #808080;
}

#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #fff;
}

.results-table {
  margin: 0 auto;
  text-align: start;
  border-radius: 5px;
}

.results-table td {
  padding: 10px;
  background: #F0F0F0;
}

.results-table th, .results-table td {
  border: 1px solid white;
}

div#share-card {
  width: 320px;
  height: 320px;
  background: #de3729;
  color: white;
//margin-left: auto; //margin-right: auto; padding: 15px; position: relative; display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.share-card-body {
  background: white;
  color: black;
  display: flex;
  border-radius: 5px;
  justify-content: space-evenly;
  height: 180px;
  align-items: center;
  position: relative;
}

.share-card-footer {
  font-size: 10px;
  font-weight: 600;
  margin-left: auto;
}

.share-card-footer strong {
  background: #fff;
  color: #000;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 1px;
  border: 1px solid rgba(221, 221, 221, 0.65);
  font-weight: 700
}

.share-button {
  display: flex;
  justify-content: end;
  max-width: 320px;
  width: 100%;
  //width: 320px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.share-button svg {
  fill: #de3729;
  stroke-width: 1px;
  stroke: #de3729;
  margin-right: 5px;
  width: 15px;
}

.share-button button {
  border: 1px solid #de3729;
  border-radius: 7px;
  font-size: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 14px;
  padding-right: 14px;
  color: #de3729;
  display: flex;
  align-items: center;
}

.share-card-partij img {
  height: 80px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 5px;
}

.share-card-partij {
  width: 80px;
  height: 120px;
}

.share-card-body::after {
  content: '';
  width: 40px;
  display: block;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #de3729;
  position: absolute;
  top: -1px;
  left: 55px;
}

.result h1, .resultdetails h1 {
  font-size: 20px !important;
  text-align: left;
}
.resultdetails h1{
  text-align: center;
}

.result, .resultdetails {
  max-width: 320px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.result p, .resultdetails p {
  font-size: 20px !important;
  text-align: left;
  margin-bottom: 20px;
}

table.results-table {
  width: 100%;
  border: unset !important;
}

.share-card-partij-percentage {
  height: 30px;
}

.shareable {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>