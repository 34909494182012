<template>
  <div class="container">
<!--    <h1 class="mb-8">Stemwijzer</h1>-->
    <core-stemwijzer />
  </div>
</template>

<script>
import CoreStemwijzer from './components/Stemwijzer.vue';

export default {
  name: 'App',
  components: {
    CoreStemwijzer
  }
}
</script>

<style>
body, html {
  height: 100%;
  background: #222222;
  color: #dddddd;
  font-family: 'Lato', sans-serif;
}

.container {
  text-align: center;
}

h1 {
  font-size: 60px !important;
  font-weight: bold !important;
}

h2 {
  font-size: 40px !important;
  font-weight: bold !important;
}

h3 {
  font-size: 25px !important;
  font-weight: bold !important;
}

p {
  font-size: 22px !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}
</style>
